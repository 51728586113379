/**
 * Configure your Gatsby site with this file.
 *
 * See: https://www.gatsbyjs.org/docs/gatsby-config/
 */

 module.exports = {
  /* Your site config here */
  plugins: [
    'gatsby-plugin-sharp',
    'gatsby-transformer-sharp',
    'gatsby-plugin-image',
    {
      resolve: 'gatsby-transformer-remark',
      options: {
           
        plugins: [
          {
            resolve: 'gatsby-remark-relative-images-v2',
          },
          {
            resolve: 'gatsby-remark-images',
            options: {
              // It's important to specify the maxWidth (in pixels) of
              // the content container as this plugin uses this as the
              // base for generating different widths of each image.
            
              maxWidth: 590,
            },
          },
          {
            resolve: 'gatsby-remark-copy-linked-files',
            options: {
              destinationDir: 'static',
            },
          },
        ],
      },
    },
    {
      // keep as first gatsby-source-filesystem plugin for gatsby image support
      resolve: 'gatsby-source-filesystem',
      options: {
        path: `${__dirname}/static/img`,
        name: 'uploads',
      },
    },
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `slider`,
        path: `${__dirname}/src/images/slider`,
      },
    },
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `markdown-pages`,
        path: `${__dirname}/_data`,
      },
    },
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: `Schatzkammer Lübeck`,
        short_name: `Schatzkammer`,
        start_url: `/`,
        background_color: `#fff`,
        theme_color: `#6D4235`,
        display: `standalone`,
        icon: "src/images/icon.png",
      },
    },
    
    `gatsby-plugin-react-helmet`,
    `gatsby-plugin-netlify-cms`,
  ],
  siteMetadata: {
    "title": "Schatzkammer Lübeck",
    "siteUrl": "https://schatzkammer-luebeck.de",
    "description": "Die Schatzkammer Lübeck - Ihre Adresse für Antiquitäten",
    "home": {
      "title": "Startseite",
      "description": "Übersicht über unser Unternehmen und Leistungen"
    }
  },
}