import React from "react"

const PostLink = ({ post }) => (
  

     
 
  <section class="">
  
    
      
      <div dangerouslySetInnerHTML={{ __html: post.html }}/>
      
 
  
</section>
     
  )  

  export default PostLink
 
