import React from "react"
import { Link } from "gatsby"

const PostLink = ({ post }) => (
  
  <article class="w-full object-cover">
    <Link class="" to={post.frontmatter.path}>
     
      {!!post.frontmatter.thumbnail && (
        <img src={post.frontmatter.thumbnail} alt={post.frontmatter.title + "- Featured Shot"} />
      )}
     
    </Link>

  </article>
  
)


export default PostLink